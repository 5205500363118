import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { InfoIcon, SparkleIcon, TriangleAlert } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

import { Text } from '../typography/text'

const calloutVariants = cva(
  'relative w-full rounded-lg p-m flex gap-x-m flex-nowrap',
  {
    variants: {
      variant: {
        default: 'bg-neutral [&>svg]:text-subtle',
        error: 'bg-error-subtle [&>svg]:text-error',
        ai: 'bg-gradient-to-r from-primary-start to-primary-stop [&>svg]:text-primary text-primary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const VARIANT_TO_ICON = {
  default: InfoIcon,
  error: TriangleAlert,
  ai: SparkleIcon,
  page: InfoIcon,
}

/**
 * __Callout__
 *
 * @template Built from shadcn/ui Alert component 'https://ui.shadcn.com/docs/components/alert'
 *
 * @see: 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=435-272&mode=design&t=dtRN0hNyQMWL3IeO-0'
 *
 * @example
 * <Callout icon={ExampleIcon}>
 *  <CalloutDescription>Increase automation rate between 5-10% with this change.</CalloutDescription>
 * </Callout>
 */
const Callout = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof calloutVariants> & {
      icon?: React.ComponentType<{ size?: string | number; className?: string }>
    }
>(({ className, variant = 'default', icon, children, ...props }, ref) => {
  const Icon = icon || VARIANT_TO_ICON[variant!]

  return (
    <div
      className={cn(calloutVariants({ variant }), className)}
      ref={ref}
      role="alert"
      {...props}
    >
      <Icon className="size-4 shrink-0" />
      {typeof children === 'string' ? (
        <Text variant="p-s">{children}</Text>
      ) : (
        // required to allow correct alert composition
        <div className="flex flex-col gap-y-s">{children}</div>
      )}
    </div>
  )
})
Callout.displayName = 'Callout'

const CalloutHeading = ({ children }: { children: React.ReactNode }) => (
  <h2 className="!leading-4 font-heading-h3">{children}</h2>
)

const CalloutDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div className={cn('font-text-s', className)} ref={ref} {...props} />
))
CalloutDescription.displayName = 'CalloutDescription'

export { Callout, CalloutDescription, CalloutHeading }
