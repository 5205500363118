import * as React from 'react'

import { List } from '@radix-ui/react-navigation-menu'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export { NavigationMenu } from './menu'
import { cn } from '@design-system/lib/utils'

import { Text } from '../typography/text'

const listVariants = cva(
  'relative block flex-1 list-none [&_&]:hidden md:[&_&]:block [&_&]:ml-xl before:absolute before:inset-y-s before:left-xs before:w-xxs before:[&_&]:bg-neutral',
)

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof List>,
  React.ComponentPropsWithoutRef<typeof List> &
    VariantProps<typeof listVariants>
>(({ className, children, title, ...props }, ref) => (
  <List className={cn(listVariants(), className)} ref={ref} {...props}>
    {title && (
      <div className="sr-only uppercase md:not-sr-only md:px-xl md:py-m">
        <Text color="subtle" variant="p-xs">
          {title}
        </Text>
      </div>
    )}
    {children}
  </List>
))
NavigationMenuList.displayName = List.displayName

export { NavigationMenuList }
