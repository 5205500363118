import * as React from 'react'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { Circle } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

const RadioGroupContext = React.createContext<{
  disabled?: boolean
  name: string
  required?: boolean
}>({
  disabled: false,
  required: false,
  name: '',
})

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  const context = React.useMemo(
    () => ({
      disabled: props.disabled || false,
      name: props.name!,
      required: props.required || false,
    }),
    [props.name, props.disabled, props.required],
  )
  return (
    <RadioGroupContext.Provider value={context}>
      <RadioGroupPrimitive.Root
        className={cn('grid gap-2', className)}
        {...props}
        ref={ref}
      />
    </RadioGroupContext.Provider>
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>(({ className, id, form, ...props }, ref) => {
  const { required, disabled, name } = React.useContext(RadioGroupContext)
  return (
    <div className={className}>
      <input
        className="peer sr-only"
        disabled={disabled}
        form={form}
        id={id}
        name={name}
        ref={ref}
        required={required}
        type="radio"
        {...props}
      />
      <label
        className="ring-offset-background peer-focus:ring-ring flex aspect-square h-4 w-4 items-center justify-center rounded-full border border-primary text-inverse hover:cursor-pointer peer-checked:text-primary peer-focus:outline-none peer-focus-visible:ring-2 peer-focus-visible:ring-offset-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-50"
        htmlFor={id}
      >
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </label>
    </div>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
