import * as React from 'react'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  /**
   * id of the checkbox.
   * @required for accessibility reasons.
   */
  id: string
  /**
   * The label of the checkbox. You should always provide a label for accessibility reasons. If you don't want to display a label, you can provide an empty string.
   * However ensure provide an alternative way to identify the checkbox eg an an id and an external `labelFor`
   */
  label: string
}

export const checkboxContainerMixin =
  'ring-offset-background focus-visible:ring-ring peer h-4 w-4 shrink-0 rounded-sm border border-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-inverse'

/**
 * __Checkbox__
 *
 * A checkbox is a form element that allows the user to select one or more options from a range of options.
 *
 * @example
 * <Checkbox label="I agree to the terms and conditions" id="some-unique-id" />
 * @example
 * <Checkbox label="I agree to the terms and conditions" id="some-unique-id" defaultChecked />
 */
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <div className="flex items-center gap-x-s">
    <CheckboxPrimitive.Root
      className={cn(checkboxContainerMixin, className)}
      ref={ref}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        <Check className="h-4 w-4" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {props.label && (
      <label
        className="cursor-pointer select-none font-text-s"
        htmlFor={props.id}
      >
        {props.label}
      </label>
    )}
  </div>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
